import network from '../network';

export default class Gisip {
    static async getTableData(formId, type, data) {
        let res = await network.get(`review/${type}/${formId}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async updateTableData(payload) {
        let res = await network.post(`review/${payload.name}`, payload.data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async acceptTableData(name) {
        let res = await network.post(`/review/${name}/accept`)
        if (res.isSuccess) {
            return res.result
        }
    }

    static async downloadReport({formId, year}) {
        return await network.get(`download/${formId}/${year}`, null, {});
    }
}