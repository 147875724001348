import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        props: true,
        component: () => import ('../views/Home.vue')
    },
    {
        path: '/form/:formId/:year',
        name: 'formTenantDetail',
        props: true,
        component: () => import ('../views/FormDetail.vue')
    },
    {
        path: '/form/:formId/:year/:quarter',
        name: 'formDetail',
        props: true,
        component: () => import ('../views/FormDetail.vue')
    },
    {
        path: '/form/:formId',
        name: 'form',
        props: true,
        component: () => import ('../views/Form.vue')
    },
    {
        path: '/review/:formId',
        name: 'review',
        props: true,
        component: () => import ('../views/Review.vue')
    },
    {
        path: '/gisip_technopark/:formId',
        name: 'gisip_technopark',
        props: true,
        component: () => import ('../views/GisipDetail.vue')
    },
    {
        path: '/gisip_industrial_park/:formId',
        name: 'gisip_industrial_park',
        props: true,
        component: () => import ('../views/GisipDetail.vue')
    },
    {
        path: '/review/:formId/:year/:quarter',
        name: 'reviewDetail',
        props: true,
        component: () => import ('../views/ReviewDetail.vue')
    },
    {
        path: '/review/statistic',
        name: 'reviewAll',
        props: true,
        component: () => import ('../views/ReviewStatistics.vue')
    },
    {
        path: '/mer/:formId/:page',
        name: 'mer',
        props: true,
        component: () => import ('../views/Mer.vue')
    },
    {
        path: '/mer/:formId/:year/:quarter',
        name: 'merDetail',
        props: true,
        component: () => import ('../views/MerDetail.vue')
    },
    {
        path: '/minec/:formId/:page',
        name: 'minec',
        props: true,
        component: () => import ('../views/Minec.vue')
    },
    {
        path: '/minec/:formId/:year/:quarter',
        name: 'minecDetail',
        props: true,
        component: () => import ('../views/MinecDetail.vue')
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import('../views/Forbidden.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/forbidden'
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
