<template>
	<div class="multi-select"
	     :class="{'multi-select_disabled': isDisabled }"
	     ref="multiSelect"
	     @blur="hideOptions"
	     tabindex="0"
	>
		<div class="multi-select__result-wrap"
		     @click="toggleVisibleOptions"
		>
			<div v-if="!activeOptions.length">
				<p class="multi-select__result ">Не выбрано</p>
			</div>
			<div v-else-if="activeOptions.length < 1">
				<p class="multi-select__result" v-for="item in dataOptions" :key="item.id">{{ item.title }}</p>
			</div>
			<p class="multi-select__result" v-else>
				Выбрано <span>{{ activeOptions.length }} </span> из <span>{{ dataOptions.length }}</span>
			</p>
		</div>
		<div class="multi-select__options"
		     :class="{ 'multi-select__options_show': showOptions }"
		     v-if="!isDisabled"
		     ref="inputQuery"
		>
			<div class="multi-select__search-wrap">
				<input
					type="search"
					class="multi-select__input multi-select__input_search"
					v-model="query"
					@blur="hideOptions"
				/>
			</div>

			<label class="multi-select__option" :value="item.title" v-for="item in listItems" :key="item.id">
				<input
					class="multi-select__input multi-select__input_radio"
					type="checkbox"
					name="multi-select"
					:value="item.title"
					:checked="item.checked"
					@click="setState(item)"
				/>
				<span class="multi-select__result multi-select__result_option"> {{ item.title }}</span>
			</label>
		</div>
	</div>
</template>
<script>
export default {
	name: 'MultiSelect',
	props: {
		active: {
			type: Array,
			default() {
				return [];
			},
		},
		dataSelect: {
			type: Array,
			default() {
				return [];
			},
		},
		inputData: [Array, Object],
		isDisabled: Boolean,
		entityId: [String, Number],
	},
	data() {
		return {
			dataOptions: [],
			activeOptions: [],
			showOptions: false,
			query: '',
		};
	},
	watch: {
		active: 'init'
	},
	mounted() {
		this.init();
	},
	computed: {
		valueSelect() {
			if (this.active) {
				return this.active.length;
			}
			return 0
		},
		listItems() {
			if (this.query) {
				let query = this.query.toLowerCase();
				return this.dataOptions.filter((item) => {
					return item.title.toLowerCase().indexOf(query) !== -1;
				});
			} else {
				return this.dataOptions;
			}
		},
	},
	methods: {
		init() {
			if (this.dataSelect && this.dataSelect.length) {
				this.dataOptions = [...this.dataSelect];
				if (this.active) {
					this.dataOptions.forEach(option => {
						option.checked = false;
						this.active.forEach(activeEl => {
								if (activeEl == option.value) {
									option.checked = true
								}
							}
						)
					});
				}
			}
			if (this.active) {
				this.activeOptions = [...this.active]
			}
		},
		setState(item) {
			this.dataOptions.forEach(option => {
				if (option.value == item.value) {
					option.checked = !option.checked
				}
			})
			let idx = this.activeOptions.indexOf(item.value);
			if (idx != -1) {
				this.activeOptions.splice(idx, 1);
			} else {
				this.activeOptions.push(item.value)
			}
			let data = this.activeOptions;
			if (this.entityId) {
				data = {
					id: this.entityId,
					value: data
				};
			}
			this.$emit('sendEvt', data);
		},
		visibleOptions() {
			this.showOptions = true;
		},
		toggleVisibleOptions() {
			this.showOptions = !this.showOptions;
		},
		hideOptions(e) {
			if (e.relatedTarget && (this.$refs.multiSelect.contains(e.relatedTarget) || this.$refs.multiSelect === e.relatedTarget))
				return;

			if (!e.relatedTarget || !e.target.contains(e.relatedTarget))
				this.showOptions = false;
		},
	},
};
</script>

<style lang="scss">

.multi-select {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	height: 40px;
	position: relative;
	border: 1px solid $color-light-grey;
	border-radius: 5px;
	transition: $transition-main;

	&_disabled {
		pointer-events: none;
		border: none;
		background-color: $color-light-grey;
	}

	&::before {
		content: '';
		position: absolute;
		display: flex;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		background: url("~@/assets/icons/arrow_down.svg") no-repeat center;
		width: 14px;
		height: 14px;

	}

	&:hover {
		border: 1px solid $color-danger;

		&::before {
			background: url("~@/assets/icons/arrow_down_red.svg") no-repeat center;
		}
	}

	&__options {
		transition: $transition-main;
		opacity: 0;
		position: absolute;
		top: 42px;
		width: 100%;
		max-height: 300px;
		overflow-y: auto;
		box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
		display: none;
		flex-direction: column;
		justify-content: space-between;
		z-index: 999;
		background-color: $color-white;
	}

	&__options_show {
		opacity: 1;
		display: flex;
	}

	&__option {
		position: relative;
		font-family: $font;
		font-size: 14px;
		font-weight: 400;
		min-height: 30px;
		display: flex;
		align-items: center;
		border-left: 1px solid transparent;
		border-right: 1px solid transparent;
		border-bottom: 1px solid transparent;
		border-top: 1px solid $color-light-grey;

		&:last-child {
			border-bottom: 1px solid $color-light-grey;
		}

		&:hover {
			color: $color-danger;
			border: 1px solid $color-danger;
			cursor: pointer;

			.multi-select__input_radio:checked + span::after {
				background-image: url("~@/assets/icons/mark.svg");
			}
		}
	}

	&__input_radio {
		visibility: hidden;
		position: absolute;
		z-index: -1;

		& + span::after {
			background-image: url("~@/assets/icons/mark.svg");
		}

		&:checked + span::after {
			content: '';
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			display: inline-block;
			width: 14px;
			height: 14px;
			flex-shrink: 0;
			flex-grow: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100%;
		}
	}

	&__result {
		user-select: none;
		width: 100%;
		color: $color-grey;
	}

	&__result_option {
		position: relative;
		padding: 10px 24px 10px 12px;
	}

	&__result-wrap {
		display: flex;
		width: 100%;
		padding: 12px;
		cursor: pointer;
		font-family: $font;
		font-size: 14px;
		font-weight: 400;
	}

	&__search-wrap {
		display: flex;
		width: 100%;
		cursor: pointer;
		font-family: $font;
		font-size: 14px;
		font-weight: 400;
		color: $color-grey;
		min-height: 64px;
		margin: 0 auto;
		padding: 12px;
	}

	&__input_search {
		font-family: $font;
		font-size: 14px;
		font-weight: 400;
		color: $color-grey;
		outline: none;
		width: 100%;
		padding-left: 12px;
		border: 1px solid $color-light-grey;
		border-radius: 5px;
		height: 40px;

		&:focus {
			border: 1px solid $color-danger;
			outline: none;
		}
	}
}
</style>
